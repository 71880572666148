import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import ArtCard from "./ArtCards"
import Particle from "../Particle";

import dots from "../../Assets/Projects/dots.png";


import gardenpatch from "../../Assets/Projects/gardenpatch.gif";

import shinigami from "../../Assets/shinigamiart.jpeg"
import shinigaminew from "../../Assets/shinigaminewart.jpg"

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container className="fade-in">
        <br></br>
        <h1 className="project-heading">
          <strong className="purple">Shinigami</strong>art
        </h1>
        
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={5} className="project-card">
          <ArtCard
              imgPath={shinigami}
              description="art by Slum Chemist R.Y"
            />
          </Col>
          <Col md={5} className="project-card">
          <ArtCard
              imgPath={shinigaminew}
              description="art by Slum Chemist R.Y"
            />
          </Col>
        </Row>
        
        <h1 className="project-heading">
          <strong className="purple">NFT</strong>s
        </h1>
        <p style={{ color: "white" }}>
          We are  on <strong className="purple">Polygon</strong>.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
          <ProjectCard
              imgPath={gardenpatch}
              isBlog={false}
              title="Garden Patch"
              description="Garden Patch Veggies collection commemorating the release of their very first EP. First 20% Veggies are 2 MATIC!"
              link="https://mintveggies.broccolirecords.com/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={dots}
              isBlog={false}
              title="DOTs"
              description="42000 DOTs"
              link="https://dots.dblz.art"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
