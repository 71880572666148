import React from 'react';
import { Container, Row, Col } from "react-bootstrap";


import Particle from "../Particle";
import Skit from './Skit';



function About() {
  return (
    <Container fluid className="about-section">
        <Particle />
        <Row>
            <Col md={12} className="about-header">
            <Skit />
            
            
            
            </Col>
        </Row>
        

    </Container>
  );
}

export default About;