import React from 'react';
import Scramble from 'react-scramble';

class ScrambleText extends React.Component {
  render() {
    return (
      <Scramble
        autoStart = 'true'
        mouseEnterTrigger='restart'
        mouseLeaveTrigger='pause'
        speed='slow'
        
        text="SLUM CHEMIST R.Y"
        steps={[
          {
            roll: 20,
            action: '+',
            type: 'random',
          },
          {
            roll:20,
            action: '-',
            type: 'forward',
          },
          {
            roll:20,
            action: '',
            type: 'forward',
          },
          {
            roll:20,
            action: '+',
            type: 'forward',
          },
          {
            roll: 5,
            action: '+',
            type: 'random',
          },
          {
            roll: 30,
            action: '-',
            type: 'forward',
          },
          
        ]}
      />
    )
  }
}
export default ScrambleText