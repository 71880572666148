import React from 'react'
import { Container , Image } from 'react-bootstrap'
import styled from 'styled-components'
import Logo from '../../Assets/shinigamilovelogo.png'
import Type from './Type'


const H1 = styled.h1`
    font-size: 20px;

`

const Skit = () => {
  return (

    <>
    
    <Image className='fade-in' src={Logo} height={150}/>
    
    <h1 style={{ paddingBottom: 0 }} className="heading">
    <span className="text-blur-out" role="text" >死神の愛</span>
    </h1>    
    
    <Container >
    
    <H1 className='fade-in'><span style={{ paddingBottom: 0 }} className="purple">Shlum</span>'s 2nd studio EP: <span className="text-blur-out red" role="text" aria-labelledby="text-blur-out">Shinigami Love</span> (<span style={{ paddingBottom: 0 }} className="red">Shinigami No Ai</span>), released on Shlum’s Birthday 14 February 2022, by <span style={{ paddingBottom: 0 }} className="green">Broccoli Records</span>. 

    <span style={{ paddingBottom: 0 }} className="red">Shinigami Love</span> includes features from <span style={{ paddingBottom: 0 }} className="green">Broccoli</span> artists, <span style={{ paddingBottom: 0 }} className="green">DBL Z</span> , <span style={{ paddingBottom: 0 }} className="furyred">Fury</span>.

          The EP features productions from a variety of upcoming independent producers, including <span style={{ paddingBottom: 0 }} className="gray">Hanto</span>, <span style={{ paddingBottom: 0 }} className="gray">VintageMan</span>, <span style={{ paddingBottom: 0 }} className="gray">KeyK</span>, <span style={{ paddingBottom: 0 }} className="gray">Vilão 90s</span>  and <span style={{ paddingBottom: 0 }} className="gray">Antidote</span>.

          Recording sessions for <span style={{ paddingBottom: 0 }} className="red">Shinigami Love</span> Love took place on <span style={{ paddingBottom: 0 }} className="furyred">Hell on Earth</span>, next to <span style={{ paddingBottom: 0 }} className="darkpurple">Hades</span>…. I mean <span style={{ paddingBottom: 0 }} className="green">Grandmaster Z</span> (“aka” <span style={{ paddingBottom: 0 }} className="lightpurple">Zeezee</span>) at <span style={{ paddingBottom: 0 }} className="green">Broccoli Studios</span> and <span style={{ paddingBottom: 0 }} className="purple">Slum’s Ghetto studio</span> (aka <span style={{ paddingBottom: 0 }} className="darkpurple">The Chemist Slums</span>).

          </H1>  
          <iframe className='fade-in' width="250" height="315" src="https://www.youtube-nocookie.com/embed/ZsLs5uW1Png" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </Container>
        </>
  )
}

export default Skit