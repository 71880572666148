import React from "react";
import Particles from "react-tsparticles";

function Particle() {
  return (
    <Particles
      params={{
        particles: {
          number: {
            value: 160,
            density: {
              enable: true,
              value_area: 1500,
            },
          },
          shape: {
            type: "circle",
            stroke: {
              width: 0,
              color: "#000000"
            }

          },
          opacity: {
            value: 0.5,
            random: false,
            anim: {
              enable: true,
              speed: 1,
              opacity_min: 0.05,
              sync: false,
            },
          },
          size: {
            value: 2.017060304327615,
            randon:true,
            anim: {
              enabled:true,
              speed:7,
              size_min:0.1,
              sync:false,
            }
          },
          line_linked: {
            enable: false,
            distance: 150,
            opacity: 0.03,
            width:1,
          },
          move: {
            enabled: true,
            speed: 6,
            direction: "bottom",
            random: false,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: {
              enabled: true,
              rotateX: 600,
              rotateY: 1200,
            }

          }
          
        },
        interactivity: {
          detect_on:"window",
          events: {
            onhover:{
              enable:true,
              mode:"grab",

            },
            onclick: {
              enable: true,
              mode: "push",
            },
          },
          modes: {
            push: {
              particles_nb: 1,
            },
          },
        },
        retina_detect: true,
      }}
    />
  );
}

export default Particle;
