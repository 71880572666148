import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import Particle from "../Particle";

import Scramble from "./ScrambleText";


function Home() {
  return (
    
      <Container fluid className="home-section" id="home">
        <Particle />
        
          <Row>
            <Col md={12} className="home-header fade-in">
              <h1 style={{ paddingBottom: 0 }} className="red-heading">
              死神の愛 <span className="text-blur-out" role="img" aria-labelledby="text-blur-out">♥</span>
              </h1>

              <h1 className="heading-name">
                <strong className="main-name"><Scramble /></strong>
              </h1>

              <div style={{ padding: 50, textAlign: "left" }}>
                
              </div>
              
              {/* <div style={{ padding: 35, textAlign: "left" }}>
                <Type2 />
              </div> */}
            </Col>

            
          </Row>
        
      </Container>
      
  );
}

export default Home;
